import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function ProductsPage({ data }) {
  return (
    <Layout>
      <SEO title="Products" />
      <h2>Products</h2>
      <Link to="/products/pacingtimer">
        <Img fixed={data.appImage.childImageSharp.fixed} />
      </Link>
      <h3>Pacing Timer</h3>
    </Layout>
  );
}

export const query = graphql`
  query {
    appImage: file(relativePath: { eq: "timer.png" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
